.sidebar {
    width: 250px; /* Adjusted width for better spacing */
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #000; /* Match the dark background */
    padding: 0;
    margin: 0;
    transition: transform 0.3s ease-in-out;
    z-index: 100; /* Ensure it's above other content */
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
  }
  
  .sidebar-footer {
    position: absolute; /* Positions the footer at the bottom of the sidebar */
    bottom: 0; /* Aligns the footer to the bottom */
    width: 100%; /* Ensures the footer spans the width of the sidebar */
    text-align: center; /* Centers the text in the footer */
    padding: 0px;
    margin: 20px 0px;
    box-sizing: border-box; /* Ensures padding is included in width */
  }
  
  .main-content {
    transition: margin-left 0.3s ease-in-out;
  }
  
  .sidebar-visible {
    margin-left: 250px; /* Match the width of the sidebar */
  }
  
  .sidebar.hidden {
    transform: translateX(-100%);
  }
  
  .sidebar.show {
    transform: translateX(0);
  }
  
  .sidebar a {
    color: #fff; /* White text */
    display: flex;
    align-items: center;
    padding: 10px 8px; /* Adjust padding as needed */
    margin: 4px 10px; /* Adjust margin as needed */
    text-decoration: none;
    font-size: 16px; /* Adjust font size as needed */
    font-weight: 500; /* Bold font */
    border-radius: 8px; /* Rounded corners for the links */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover and active states */
  }
  
  .sidebar a:hover, .sidebar a.active {
    background-color: rgba(32, 33, 35, 1);
  }
  
  .toggle-button {
    position: fixed;
    left: 10px; /* Adjust position to match design */
    top: 10px;
    background: none;
    border: none;
    color: #000;
    font-size: 24px; /* Larger touch area for mobile */
    z-index: 110; /* Above the sidebar */
  }
  
  .sidebar-sign-out {
    background: none;
    border: none;
    color: #fff; /* White text */
    width: calc(100% - 20px);;
    display: flex;
    align-items: center;
    padding: 10px 8px; /* Adjust padding as needed */
    margin: 4px 10px; /* Adjust margin as needed */
    text-decoration: none;
    font-size: 16px; /* Adjust font size as needed */
    font-weight: 500; /* Bold font */
    border-radius: 8px; /* Rounded corners for the links */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover and active states */
    cursor: pointer; /* Changes the mouse cursor to a pointer */
    box-sizing: border-box; /* Includes padding and border in the width */
    justify-content: center; /* Center the text horizontally */
    background-color: rgba(32, 33, 35, 1);
  }
  
  .sidebar-sign-out:hover {
    background-color: red; /* Same as the hover effect for links */
  }
  
  .sidebar-overlay {
    display: none; 
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 99; /* Right below the sidebar */
  }
  
  @media (max-width: 768px) {
    .sidebar {
      transform: translateX(-100%); /* Start off-screen */
      z-index: 100;
    }
    
    .toggle-button {
      display: block; /* Only show button on mobile */
    }
    .sidebar-visible {
      margin-left: 0; /* No margin on mobile since sidebar is full width */
    }
    .sidebar.show {
      display: block; /* Show overlay when sidebar is open */
    }
    .sidebar-overlay {
      display: block; /* Show overlay when sidebar is open */
    }
    
  }
  
  @media (min-width: 769px) {
    .sidebar {
      transform: translateX(0); /* Always visible on desktop */
    }
   
  }

  .sidebar-category {
    font-size: 14px;
    font-weight: 500;
    color: #666;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
:root {
  --accent-color: rgba(88, 180, 237);
  --selected-color: #202123;
}

.loading-indicator {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(88, 180, 237, 0.3); /* Light circle */
  border-radius: 50%;
  border-top: 3px solid var(--accent-color); /* Dark circle portion */
  animation: spin 1s linear infinite;
}

.primaryButton {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    font-weight: 550;
    background-color: var(--accent-color);
    color: #f1f1f1;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

/* Form.css */
.text-form {
  flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        max-width: 360px;
        margin: 0 auto;
        padding: 20px;
        background-color: #fff;
}

.text-form h1 {
  color: var(--selected-color);
  text-align: center;
  font-size: 24px; /* Adjust as per your design */
  margin-bottom: 16px;
  font-weight: bold;
}

.text-form h2 {
  color: var(--selected-color); /* A secondary color that complements the primary color */
  text-align: center;
  font-size: 18px;
  margin-bottom: 12px;
  font-weight: normal;
}

.text-form label {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  display: block; /* Makes the label take up its own line */
  margin: 5px 0px;
}

.text-form-label {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  display: block; /* Makes the label take up its own line */
  margin: 5px 0px;
}

.text-form input[type="text"] {
  width: 100%;
  font-size: 16px;
  padding: 16px 10px 12px 10px;
  border-radius: 8px;
  background-color: #f0f0f0;
  border: 0px solid #ddd;
  box-sizing: border-box;
}

.text-form input[type="datetime-local"] {
  width: 100%;
  font-size: 16px;
  padding: 16px 10px 12px 10px;
  border-radius: 8px;
  background-color: #f0f0f0;
  border: 0px solid #ddd;
  box-sizing: border-box;
}

.text-form input:focus {
  outline: none;
  box-shadow: 0;
  border-color: none;
}

.text-form input[type="email"] {
  width: 100%;
  font-size: 16px;
  padding: 16px 10px 12px 10px;
  border-radius: 8px;
  background-color: #f0f0f0;
  border: 0px solid #ddd;
  box-sizing: border-box;
}

.text-form .disabled-input {
  background-color: #f0f0f0; /* Light grey background */
  color: #666; /* Darker text to show it's not editable */
  cursor: not-allowed;
}

/* Add this to ensure responsiveness */
@media (max-width: 768px) {
  .text-form {
    width: 90%;
    margin: 20px auto;
  }
}

.styledPicker {
  width: 100%;
  font-size: 16px;
  padding: 12px 10px;
  margin: 0;
  border-radius: 8px;
  background-color: #f0f0f0;
  border: 0px solid #ddd;
  box-sizing: border-box;
}


/*Verification Container*/
.verification-container {
  display: flex;
  justify-content: flex-start;
}

.verification-container input {
  padding: 0;
  text-decoration: none;
  margin: 0px 10px 0px 0px;
  width: 10vw;
  max-width: 40px;
  max-height: 40px;
  height: 10vw;
  text-align: center;
  font-size: 22px;
  border: 2px solid transparent;
  background-color: #f5f5f7;
  border-radius: 8px;
  outline: none;
}

.verification-container input:focus {
  border-color: var(--accent-color);;
}

/* Dividers */
.dividerHorizontal {
  height: 0.5px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.dividerVertical {
  width: 0.5px;
  height: calc(100%-10px);
  background-color: rgba(0, 0, 0, 0.2);
}

/* Stacks */
.hStack {
  display: flex;
  flex-direction: row; /* Align children horizontally */
  align-items: center; /* Align children vertically in the center */
  justify-content: flex-start; /* Align children to the start of the flex container */
}

.vStack {
  display: flex;
  flex-direction: column; /* Align children vertically */
  align-items: flex-start; /* Align children to the start horizontally */
  justify-content: center; /* Align children in the center vertically */
}

/* friendly message */ 
.friendly-message {
  color: #6e6e6e; /* Soft grey color */
  font-size: 14px; /* Smaller text size for subtlety */
  line-height: 1.6; /* Slightly more space between lines for readability */
  margin-bottom: 15px; /* Space between paragraphs */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* System default font */
}

.friendly-message a {
  color: #007aff; /* Apple's default link color */
  text-decoration: none; /* No underline for a cleaner look */
}

.friendly-message a:hover {
  text-decoration: underline; /* Underline on hover for accessibility */
}

/* Invitiations */

.invitationsSection h2 {
  text-align: left;
  font-weight: bold;
}

/* Billing settings */
.billing-container {
  color: #333;
  padding: 20px;
}
.payment-method {
  align-items: left;
  background-color: #f1f1f1;
  padding: 12px 20px;
  width: fit-content;
  min-width: 140px;
  border-radius: 12px;
  margin-bottom: 10px;
}

.payment-method p, h4 {
  margin: 5px 0px;
}

.default-payment-method {
  border: none;
  color: gray;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 5px;
  border-radius: 4px;
}

.add-method {
  background-color: var(--accent-color);
  border: none;
  color: #f1f1f1;
  padding: 10px 12px;
  text-align: center;
  font-weight: 550;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

.statement-list {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 4px;
}

.statement-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.statement-item p {
  margin: 0;
}

.service-fees p {
  color: #666;
}

/* Add responsive design */
@media (max-width: 768px) {
  .payment-method, .statement-item {
    flex-direction: column;
    align-items: flex-start;
  }
}


.searchBox {
  font-size: 16px;
  padding: 10px 0px 10px 12px;
  color: black;
  text-decoration: none;
  border: 0;
  border-radius: 8px;
  background: #ffffff;
  margin: 0px 0px 0px 0px;
  width: 100%;
  box-sizing: border-box;
}

.addressBox {
  font-size: 16px;
  padding: 10px 0px 10px 12px;
  color: black;
  text-decoration: none;
  border: 0;
  border-radius: 8px;
  background: #ffffff;
  margin: 0px 0px 0px 0px;
  width: 100%;
  box-sizing: border-box;
}

.searchBox:focus {
  outline: none;
}

.addressBox:focus {
  outline: none;
}

.saved-address-cell {
  align-items: left;
  background-color: #ffffff;
  padding: 12px 20px;
  width: 100%;
  min-width: 140px;
  max-width: 50vw;
  border-radius: 12px;
  box-sizing: border-box;
}

.truncate-text { 
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1;
           line-clamp: 1; 
   -webkit-box-orient: vertical;
}

/* Advertising */

.advertising-options-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px
}

.advertising-option {
  align-items: left;
  text-align: left;
  background-color: #f1f1f1;
  padding: 20px 20px;
  width: 100%;
  min-width: 140px;
  border-radius: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.advertising-option-active {
  align-items: left;
  text-align: left;
  background-color: #f1f1f1;
  padding: 20px 20px;
  width: 100%;
  min-width: 140px;
  border-radius: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 3px solid var(--accent-color);
}

.advertising-option__title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.advertising-option__activation {
  font-size: 12px;
  margin-bottom: 20px;
}

.advertising-option__month {
  font-size: 14px;
  margin-bottom: 20px;
}

.advertising-option__price {
  font-weight: bold;
  font-size: 24px;
}

.advertising-option__text {
  font-size: 14px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .advertising-options-container {
    flex-direction: column; /* Stack options vertically */
    justify-content: center; /* Center options vertically */
    align-items: center; /* Center options horizontally */
  }

  .advertising-option {
    margin-right: 0; /* Remove margin-right on smaller screens */
    margin-bottom: 10px; /* Add margin-bottom for spacing */
  }
}


.advertising-summary {
  align-items: left;
  text-align: left;
  background-color: #f1f1f1;
  padding: 10px 10px;
  width: 100%;
  box-sizing: border-box;
  min-width: 140px;
  border-radius: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.advertising-summary-infoblock {
  align-items: left;
  text-align: left;
  width: 100%;
}

/* Info Box */

.overviewBox {
  display: flex;
  flex-direction: column;
  margin: 20px;
  background-color: white;
  border-radius: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.info-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  position: relative;
  width: 50%;
  margin: 10px;
}

.info-title {
  font-size: 0.75rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  align-self: flex-start;
}

.info-value {
  font-size: 1.25rem;
  font-weight: 700;
  align-self: flex-end;
}

.info-padding {
  height: 15px;
}

.dividerHorizontal {
  height: 0.3px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.dividerVertical {
  width: 0.3px;
  height: calc(100%-10px);
  background-color: rgba(0, 0, 0, 0.2);
}

.formSection {
  background-color: white;
  border-radius: 12px;
  margin: 0px 20px 20px 20px;
  box-sizing: content-box;
}

.formTitle {
  font-size: 0.75rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  align-self: flex-start;
  margin: 40px 20px 10px 30px;
}

.formSection p {
  display: block;
  padding: 0px 10px;
  color: black;
  text-decoration: none;
}

.formBackground {
  background-color: #F2F2F7;
  width: 100%;
  height: 100%;
}

.addressTitle {
  font-size: 0.75rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  align-self: flex-start;
  margin: 10px 20px 5px 30px;
}

.addressText {
  align-self: flex-start;
  margin: 0px 20px 10px 30px;
}

.adBlockTitle {
  font-size: 0.75rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  align-self: flex-start;
  margin: 0px 0px 0px 0px;
}

.adBlockText {
  align-self: flex-start;
  margin: 0px 0px 0px 0px;
}

.cancelButton {
  padding: 15px;
  font-size: 16px;
  font-weight: 550;
  background-color: red;
  color: #f1f1f1;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
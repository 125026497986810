.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Slightly lighter overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: background-color 0.3s ease; /* Smooth transition for background */
  }
  
  .popup-content {
    background: #fff;
    padding: 20px 20px; /* Increased horizontal padding */
    border-radius: 12px; /* More pronounced rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Softer shadow */
    transition: transform 0.3s ease-out; /* Smooth transition for popup */
  }
  
  .popup-content:hover {
    transform: scale(1); /* Scale up on hover for a subtle interaction */
  }
  
  .popup-close-button, .popup-action-button {
    background: none;
    color: var(--accent-color); /* Apple's typical blue */
    padding: 10px 20px;
    margin-top: 10px;
    margin-right: 10px; /* Spacing between buttons */
    border-radius: 12px; /* Rounded corners */
    cursor: pointer;
    border: 0;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
  }
  
  .popup-action-button {
    background: var(--accent-color);
    color: white; /* Apple's typical blue */
    padding: 10px 20px;
    margin-top: 10px;
    margin-right: 10px; /* Spacing between buttons */
    border-radius: 12px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
  }
  
  .popup-close-button:hover, .popup-action-button:hover {
    background-color: var(--accent-color);
    color: white; /* Invert colors on hover */
  }
  
  .popup-close-button {
    border-color: #ff3b30; /* Red color for the cancel/close button */
    color: #ff3b30;
  }
  
  .popup-close-button:hover {
    background-color: #ff3b30;
  }